import { useState } from "react";
import TicTacToe from "./TicTacToe";
import { Winner, updateWinner } from "./Util";

type ExtendedTicTacToeBoard = { winner: Winner | undefined, disabled: boolean }[][];

const ExtendedTicTacToe: React.FC<{ onClick: (winner: Winner | undefined) => void }> = ({ onClick }) => {
    const [winner, setWinner] = useState<Winner>();
    const [matrixComputedBoardState, setMatrixComputedBoardState] = useState<{ active: boolean }[][]>(
        [
            [{ active: true }, { active: true }, { active: true }],
            [{ active: true }, { active: true }, { active: true }],
            [{ active: true }, { active: true }, { active: true }]
        ]
    );

    const [matrixBoardState, setMatrixBoardState] = useState<ExtendedTicTacToeBoard>([
        [{ winner: undefined, disabled: false }, { winner: undefined, disabled: false }, { winner: undefined, disabled: false }],
        [{ winner: undefined, disabled: false }, { winner: undefined, disabled: false }, { winner: undefined, disabled: false }],
        [{ winner: undefined, disabled: false }, { winner: undefined, disabled: false }, { winner: undefined, disabled: false }]
    ]);

    const handleClick = (cellRow: number, cellCol: number, boardRow: number, boardCol: number, winner: Winner | undefined, disabled: boolean) => {
        const tempMatrixBoardState = matrixBoardState;
        tempMatrixBoardState[boardRow][boardCol] = { winner: winner, disabled: disabled };


        /**
         * Using this matrix we are able to understand if a TicTacToe has available moves
         * 1.   If winner; NO
         * 2.   If disabled; NO
         * 3. Other wise yes
         * 
         */
        let identifiedBoardHasValidMove = false;
        if (cellRow === boardRow && cellCol === boardCol) {
            //Same board
            if (winner || disabled) {
                //doesn't have valid move
            } else {
                identifiedBoardHasValidMove = true;
            }
        } else {
            //different board
            if (matrixBoardState[cellRow][cellCol].winner || matrixBoardState[cellRow][cellCol].disabled) {
                //doesn't have valid move
            } else {
                identifiedBoardHasValidMove = true;
            }
        }

        if (identifiedBoardHasValidMove) {
            setMatrixComputedBoardState([0, 1, 2].map(row => {
                return [0, 1, 2].map(col => {
                    if (row === cellRow && col === cellCol) {
                        return { active: true };
                    } else {
                        return { active: false };
                    }
                })
            }))
        } else {
            setMatrixComputedBoardState([0, 1, 2].map(row => {
                return [0, 1, 2].map(col => {
                    if (row === cellRow && col === cellCol) {
                        return { active: false };
                    } else {
                        return { active: true };
                    }
                })
            }))
        }

        const probableWinner: Winner | undefined = updateWinner(matrixBoardState.map(row => row.map(col => col.winner?.player)));

        setMatrixBoardState(tempMatrixBoardState);
        setWinner(probableWinner);
        onClick(probableWinner);
    }

    return (
        <div className={`extended-board ${winner ? `disabledDiv` : ``}`}>
            {[0, 1, 2].map((boardRow) => {
                return <div key={boardRow} className="row">
                    {[0, 1, 2].map((boardCol) => (
                        <TicTacToe
                            key={boardCol}
                            onClick={(cellRow, cellCol, winner, disabled) => { handleClick(cellRow, cellCol, boardRow, boardCol, winner, disabled); }}
                            disabled={!matrixComputedBoardState[boardRow][boardCol].active}
                        />
                    ))}
                </div>
            })}
        </div>
    );
}

export default ExtendedTicTacToe;