import { useContext } from "react"
import Cell from "./board/Cell"
import { WinnerContext } from "./Game"
import Confetti from 'react-confetti';

export const WinnerView: React.FC<{}> = () => {

    const { winner } = useContext(WinnerContext);

    return (
        <div>
            <div>Winner</div>
            <Cell
                key={`activePlayer`}
                row={-1}
                col={-1}
                onClick={() => { console.log(`winner`) }}
                winner={winner?.player}
                backgroundColor={winner?.player.color}
            />
            <Confetti
            />
        </div>
    )
}