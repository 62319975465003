type ToggleProps = {
    isToggled: boolean
    onToggle: () => void
    toggledText: string
    toggledOffText: string
}

const Toggle = (props: ToggleProps) => {

    return (
        <div>
            <p>Toggle for {props.isToggled ? props.toggledText : props.toggledOffText}</p>
            <label className="modeSwitchLabel">
            <input 
                type="checkbox" 
                className="modeSwitchInput"
                checked={props.isToggled}
                onChange={props.onToggle} />
            <span className="modeSwitchSpanSlider rounded"/>
        </label>
        </div>
    )
}

export default Toggle;