import Player from "../Player";

export const addDataToMatrix =
    (matrix: any[][], data: any, row: number, col: number):
        any[][] => {
        const updatedMatrix = matrix;
        updatedMatrix[row][col] = data;
        return updatedMatrix;
    }

export const updateWinner = (board: (Player | undefined)[][]): Winner | undefined => {

    for (let i = 0; i < 3; i++) {
        const row = board[i];
        const player = findWinner(row);
        if (player) {
            return {
                player: player,
                cells: [
                    { row: i, col: 0 },
                    { row: i, col: 1 },
                    { row: i, col: 2 }
                ]
            };
        }
    }

    for (let i = 0; i < 3; i++) {
        const row = [board[0][i], board[1][i], board[2][i]];
        const player = findWinner(row);
        if (player) {
            return {
                player: player,
                cells: [
                    { row: 0, col: i },
                    { row: 1, col: i },
                    { row: 2, col: i }
                ]
            };
        }
    }

    const diagonal1 = [board[0][0], board[1][1], board[2][2]];
    const diagonal1Winner = findWinner(diagonal1);
    if (diagonal1Winner) {
        return {
            player: diagonal1Winner,
            cells: [
                { row: 0, col: 0 },
                { row: 1, col: 1 },
                { row: 2, col: 2 }
            ]
        };
    }

    const diagonal2 = [board[0][2], board[1][1], board[2][0]];
    const diagonal2Winner = findWinner(diagonal2);
    if (diagonal2Winner) {
        return {
            player: diagonal2Winner,
            cells: [
                { row: 0, col: 2 },
                { row: 1, col: 1 },
                { row: 2, col: 0 }
            ]
        };
    }

    return undefined;
}

const findWinner = (row: (Player | undefined)[]): Player | undefined => {
    if (row.find(player => player === undefined)) return undefined;
    return Array.from(new Set(row)).length === 1 ? row[0] : undefined;
}

export const hexToRGBA = (hex: string, opacity: number): string => {
    // Remove the '#' from the beginning of the hex value
    hex = hex.replace(/^#/, '');

    // Parse the hex value into its RGB components
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);

    // Return the rgba() color value with the specified opacity
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export type Winner = {
    player: Player;
    cells: { row: number, col: number }[];
}