import useTicTacToeBoard from "./hooks/useTicTacToeBoard";
import { Winner } from "./Util";

type StandardTicTacToeProps = {
    winner: Winner | undefined
    onClick: (winner: Winner | undefined) => void
}

const StandardTicTacToe = (props: StandardTicTacToeProps) => {

    const { findProbableWinner, tictactoecontainer } = useTicTacToeBoard()

    const handleClick = (cellRow: number, cellCol: number) => {
        const probableWinner: Winner | undefined = findProbableWinner(cellRow, cellCol);
        
        props.onClick(probableWinner)
    }

    const container = tictactoecontainer(props.winner, false, handleClick)

    return (container)
}

export default StandardTicTacToe