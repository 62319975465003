import { useContext } from "react";
import { ActivePlayerContext } from "./Game";
import Cell from "./board/Cell";

export const ActivePlayer: React.FC<{}> = () => {

    const { activePlayer } = useContext(ActivePlayerContext);

    return (
        <div>
            <div>Current player</div>
            <Cell
                key={`activePlayer`}
                row={-1}
                col={-1}
                onClick={() => { console.log(`active-player`) }}
                winner={activePlayer}
                backgroundColor={activePlayer.color}
            />
        </div>
    )
}