import { useContext, useState } from "react";
import Cell from "./Cell";
import Player from "../Player";
import { ActivePlayerContext } from "../Game";
import { Winner, addDataToMatrix, updateWinner } from "./Util";

interface TicTacToeProps {
    onClick: (cellRow: number, cellCol: number, winner: Winner | undefined, disabled: boolean) => void;
    disabled?: boolean;
}
//TODO [https://suchconclave.atlassian.net/browse/VAL-19]: this class needs to be refactored. useTicTacToeBoard, and StandardTicTacToe
const TicTacToe: React.FC<TicTacToeProps> = ({ onClick, disabled }) => {

    const { activePlayer } = useContext(ActivePlayerContext);

    const DEFAULT_TICTACTOE_BOARD = [
        [undefined, undefined, undefined],
        [undefined, undefined, undefined],
        [undefined, undefined, undefined]
    ];

    const [board, setBoard] = useState<(Player | undefined)[][]>(DEFAULT_TICTACTOE_BOARD);

    const [winner, setWinner] = useState<Winner>();

    const handleClick = (cellRow: number, cellCol: number, _disabled: boolean) => {
        const updatedBoard = addDataToMatrix(board, activePlayer, cellRow, cellCol);
        const probableWinner: Winner | undefined = updateWinner(board);

        setBoard(updatedBoard);
        setWinner(probableWinner);

        if (probableWinner?.cells) {
            console.log(`Ask the cells to draw a line on itself, provide relevant information`);
            console.log(probableWinner.cells);
        }

        onClick(cellRow, cellCol, probableWinner, _disabled);
    }

    const isComplete = (board: (Player | undefined)[][]): boolean => {
        for (let row = 0; row < 3; row++) {
            for (let col = 0; col < 3; col++) {
                if (board[row][col] === undefined) return false;
            }
        }

        return true;
    }

    //TODO simplify. This sharing of disabling creates tight coupling
    const _disabled = disabled ? disabled : isComplete(board);

    //TODO instead of sending winner; disable the board
    //TODO overlay Symbol on top of the div - priority 1-

    const container = <div className={`boardContainer`}>
        <div className={`board ${winner ? 'witoutOpacity' : _disabled ? 'disabledDiv' : ''}`}>
            {[0, 1, 2].map((cellRow) => {
                return <div key={cellRow} className="row">
                    {[0, 1, 2].map((cellCol) => (
                        <Cell
                            key={cellCol}
                            row={cellRow}
                            col={cellCol}
                            onClick={(row, col) => { handleClick(row, col, _disabled); }}
                            winner={winner?.player}
                        />
                    ))}
                </div>
            })}
        </div>
        <div
            className={`${winner !== undefined ? 'overlay' : '{'}`}
            style={{ color: winner?.player.color }}
        >
            {winner?.player.symbol}
        </div>
    </div>;


    return (container);
}

export default TicTacToe;