import { useContext, useState } from "react";
import Player from "../../Player";
import { ActivePlayerContext } from "../../Game";
import { addDataToMatrix, updateWinner, Winner } from "../Util";
import Cell from "../Cell";

export const DEFAULT_TICTACTOE_BOARD = [
    [undefined, undefined, undefined],
    [undefined, undefined, undefined],
    [undefined, undefined, undefined]
];

function useTicTacToeBoard() {
    const { activePlayer } = useContext(ActivePlayerContext)
    const [ board, setBoard ] = useState<(Player | undefined)[][]>(DEFAULT_TICTACTOE_BOARD)
    const [ winner, setWinner ] = useState<Winner>()

    const findProbableWinner = (cellRow: number, cellCol: number) => {
        const updatedBoard = addDataToMatrix(board, activePlayer, cellRow, cellCol);
        const probableWinner: Winner | undefined = updateWinner(board);

        setBoard(updatedBoard);
        setWinner(probableWinner);

        if (probableWinner?.cells) {
            console.log(`Ask the cells to draw a line on itself, provide relevant information`);
            console.log(probableWinner.cells);
        }
        return probableWinner;
    }
    const tictactoecontainer = (winner: Winner | undefined, _disabled: boolean, handleClick: (cellRow: number, cellCol: number, _disabled: boolean) => void) => {
        return <div className={`boardContainer`}>
            <div className={`board ${winner ? 'witoutOpacity' : _disabled ? 'disabledDiv' : ''}`}>
                {[0, 1, 2].map((cellRow) => {
                    return <div key={cellRow} className="row">
                        {[0, 1, 2].map((cellCol) => (
                            <Cell
                                key={cellCol}
                                row={cellRow}
                                col={cellCol}
                                onClick={(row, col) => { handleClick(row, col, _disabled); } }
                                winner={winner?.player} />
                        ))}
                    </div>;
                })}
            </div>
            <div
                className={`${winner !== undefined ? 'overlay' : '{'}`}
                style={{ color: winner?.player.color }}
            >
                {winner?.player.symbol}
            </div>
        </div>;
    }
    
    return { board, winner, findProbableWinner, tictactoecontainer }
}

export default useTicTacToeBoard