import { useState } from 'react';
import './App.css';
import Toggle from './components/Toggle';
import { Winner } from './components/board/Util';
import { ActivePlayer } from './components/ActivePlayer';
import ExtendedTicTacToe from './components/board/ExtendedTicTacToe';
import { WinnerView } from './components/WinnerView';
import { ActivePlayerContext, player1, player2, WinnerContext } from './components/Game';
import StandardTicTacToe from './components/board/StandardTicTacToe';

function App() {

  const [advanced, setAdvanced] = useState<boolean>(false)
  const [activePlayer, setActivePlayer] = useState(player1)
  const [winner, setWinner] = useState<Winner>()

  const handleClick = (winner: Winner | undefined) => {
    setActivePlayer(() => activePlayer === player1 ? player2 : player1);
    setWinner(winner);
  } 

  return (
    <div className="App">
      <header className="App-header">
      <ActivePlayerContext.Provider value={{ activePlayer }}>
      <WinnerContext.Provider value={{ winner }}>
      {!winner ? <ActivePlayer></ActivePlayer> : <WinnerView></WinnerView>}
      <div style={{ marginBottom: 60 }}></div>
        <Toggle 
          isToggled={advanced}
          onToggle={() => setAdvanced(!advanced)}
          toggledOffText='advanced'
          toggledText='standard'/>
          {advanced ? 
                    <div>
                    <div style={{ marginBottom: 60 }}></div>
                    <ExtendedTicTacToe onClick={(winner) => { handleClick(winner); }} />
                    </div>
            : <div>
                <div style={{ marginBottom: 60 }}></div>
                {/* TODO: change this to be TicTacToe, Currently the class TicTacToe cannot be used as is */}
                  {/* <ExtendedTicTacToe onClick={(winner) => { handleClick(winner); }} /> */}
                  <StandardTicTacToe 
                    winner={winner}
                    onClick={(winner) => { handleClick(winner); }}/>
              </div>
          }
          </WinnerContext.Provider>
          </ActivePlayerContext.Provider>
      </header>
    </div>
  );
}

export default App;
