import { Winner } from "./board/Util";
import Player from "./Player";
import { createContext } from "react";

export const player1 = new Player('X', '#87CEEB');
export const player2 = new Player('O', '#FFD1DC');

export const ActivePlayerContext = createContext<{ activePlayer: Player }>({ activePlayer: player1 });
export const WinnerContext = createContext<{ winner: Winner | undefined }>({ winner: undefined })

const Game = () => {
    return (
        <></>
    );
}

export default Game;