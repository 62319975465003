class Player {
    symbol: string;
    color: string;

    constructor(symbol: string, color: string) {
        this.symbol = symbol;
        this.color = color;
    }
}

export default Player;