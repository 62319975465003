import React, { useContext, useState } from 'react';
import '../../App.css';
import { ActivePlayerContext } from '../Game';
import Player from '../Player';
import { hexToRGBA } from './Util';

interface CellProps {
    row: number;
    col: number;
    onClick: (row: number, col: number) => void;
    winner: Player | undefined;
    backgroundColor?: string;
}

const Cell: React.FC<CellProps> = ({ row, col, onClick, winner, backgroundColor }) => {

    const { activePlayer } = useContext(ActivePlayerContext);
    const [player, setPlayer] = useState<Player>();

    const handleClick = () => {
        setPlayer(activePlayer);
    }

    const _disabled = (player || winner) ? true : false;
    const buttonText = player ? player.symbol : '';

    return (
        <button className='square-button'
            onClick={() => { handleClick(); onClick(row, col); }}
            style={{
                backgroundColor: backgroundColor ?
                    backgroundColor :
                    (player ? hexToRGBA(player.color, winner ? 0.2 : 1) :
                        winner ? 'rgba(128, 128, 128, 0.1)' : '#FFF')
            }}
            disabled={_disabled}>
            {buttonText}
        </button>

    );
}

export default Cell;